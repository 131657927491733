import type { DeepPartial } from "~~/types/DeepPartial";
import type { Article } from "~~/types/strapi/api/article/content-types/article/article";
import type { HealthProfessional } from "~~/types/strapi/api/health-professional/content-types/health-professional/health-professional";
import type { Seo } from "~~/types/strapi/components/shared/interfaces/Seo";

interface Category {
  name: string;
  description?: string;
  image?: unknown;
}

export const defaultHealthProfessionalMeta = (healthProfessional?: HealthProfessional["attributes"]) => {
  if (healthProfessional === undefined) {
    return;
  }

  const name = healthProfessional.fullName;
  const speciality = healthProfessional.workingTitle;
  const type = healthProfessional.type || "doctor";

  return {
    metaDescription:
      type === "doctor"
        ? `Termine direkt online buchen, keine Wartezeiten und top Behandlung bei ${name}, ${speciality} in Wien. Keine Kassen.`
        : `Schnelle Termine, Online-Buchung, keine Wartezeiten vor Ort und top Betreuung: Bei ${name} bist du in guten Händen.`,
    metaTitle: `${name} - ${speciality} bei haelsi in 1020`,
  };
};

export const defaultArticleMeta = async (article: Article["attributes"]) => {
  const seo: DeepPartial<Seo> = {
    metaTitle: `${article.title}`,
  };

  if (import.meta.server) {
    const { stripHtml } = await import("string-strip-html");

    seo.metaDescription = stripHtml(article.intro).result;
  }

  if (article.image.data !== null) {
    seo.metaImage = article.image;
  }

  return seo;
};

export const defaultArticleCategoryMeta = (category: Category) => {
  const seo: DeepPartial<Seo> = {
    metaTitle: `Der haelsi Blog: der Platz für Infos, Tipps und Neuigkeiten zum Thema "${category.name}"`,
  };

  if (category.description) {
    seo.metaDescription = category.description;
  }

  if (category.image) {
    seo.metaImage = category.image;
  }

  return seo;
};
